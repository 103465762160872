//------------------------------------------------------------------------------
// properties
//------------------------------------------------------------------------------
/** @type {string} */
export const noScrollClass='scroll--none';
/** @type {string} */
export const pageHomeClass='home';

/** @type {HTMLElement} */
export const html=document.documentElement;
/** @type {HTMLElement} */
export const body=document.body;

/** @type {Object} BootstrapBreakpoints */
export const breakpoints={
  xs:{
    min:0,
    max:374
  },
  ms:{
    min:375,
    max:575
  },
  sm:{
    min:576,
    max:767
  },
  md:{
    min:768,
    max:991
  },
  lg:{
    min:992,
    max:1199
  },
  xl:{
    min:1200,
    max:1399
  },
  xxl:1400
};

/** @type {MediaQueryList} */
export const desktopMinBreakpointQuery=window.matchMedia(`screen and (min-width:${breakpoints.lg.min}px)`);

export const paths={
  images:'/wp-content/themes/Impreza-child/assets/img/'
};

export const isLoggedIn=body.classList.contains('logged-in');
export const isAdminLoggedIn=body.classList.contains('kh--admin-logged-in');

//------------------------------------------------------------------------------
// functions
//------------------------------------------------------------------------------
/**
 * @param {Element} element
 * @return {number}
 */
export const getElementPageOffset=(element) => {
  return element.getBoundingClientRect().top-document.body.getBoundingClientRect().top;
}

/**
 * animate height of an html element
 * height will be set here, but animation needs to be set in css through a transition
 *
 * @param {number} oldHeight
 * @param {number} newHeight
 * @param {HTMLElement} element
 * @param {number} duration
 * @param {function|null} onAnimatedCallback
 * @param {boolean} setHeightToAuto
 * @return {number}
 */
export const animateHeightCss=(oldHeight, newHeight, element, duration, onAnimatedCallback=null, setHeightToAuto=true) => {
  let timeout=0;
  element.style.height=oldHeight+'px';
  setTimeout(() => {
    element.style.height=newHeight+'px';
    timeout=setTimeout(() => {
      if(setHeightToAuto)
      {
        element.style.height='auto';
      }
      if(onAnimatedCallback)
      {
        onAnimatedCallback();
      }
    }, duration);
  }, 10);
  return timeout;
}

/**
 * Move all children from oldContainer to newContainer
 *
 * @param {HTMLElement} oldContainer
 * @param {HTMLElement} newContainer
 * @param {boolean} prepend
 */
export const moveChildren=(oldContainer, newContainer, prepend=false) => {
  if(prepend)
  {
    while(oldContainer.childNodes.length>0)
    {
      newContainer.insertBefore(oldContainer.childNodes[0], newContainer.firstChild);
    }
  }
  else
  {
    while(oldContainer.childNodes.length>0)
    {
      newContainer.appendChild(oldContainer.childNodes[0]);
    }
  }
}

export const disableBodyScroll=() => {
  body.style.paddingRight=(window.innerWidth-document.documentElement.clientWidth)+'px';
  body.classList.add(noScrollClass);
}

export const enableBodyScroll=() => {
  body.style.paddingRight='0';
  body.classList.remove(noScrollClass);
}

/**
 *
 * outer width with margin
 * @param el
 * @returns {number}
 */
export const getOuterWidth=(el) => {
  let width=el.getBoundingClientRect().width;
  let style=getComputedStyle(el);
  width+=parseInt(style.marginLeft)+parseInt(style.marginRight);
  return width;
}

/**
 *
 * outer height with margin
 * @param el
 * @returns {number}
 */
export const getOuterHeight=(el) => {
  let height=el.getBoundingClientRect().height;
  let style=getComputedStyle(el);
  height+=parseInt(style.marginTop)+parseInt(style.marginBottom);
  return height;
}

/**
 * @param {HTMLVideoElement} video
 * @return {boolean}
 */
export const isVideoPlaying=(video) => {
  return !!(video.currentTime>0 && !video.paused && !video.ended && video.readyState>2);
}

/**
 *
 * @source: https://codepen.io/aaroniker/pen/MWWRJzM
 * @param elem
 * @param callback
 * @param options
 */
export const isInViewport=(elem, callback, options={}) => {
  return new IntersectionObserver(entries => {
    entries.forEach(entry => callback(entry));
  }, options).observe(document.querySelector(elem));
};
