import quicklink from "quicklink/dist/quicklink.js";
//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {
  quicklink.listen({
    origins:[
      // add mine
      'brett-holzbau.de',
      'www.brett-holzbau.de',
      // add third-parties
      // 'brettbox-camping.de',
      // 'www.brettbox-camping.de',
    ]
  });
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init
};
