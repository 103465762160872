import {isLoggedIn} from '../config/util';

//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {
  if(!isLoggedIn)
  {
    // plausible Form Submission Tracking
    const form=document.querySelector('.frm-fluent-form');
    if(form)
    {
      const pages=[
        {selector:'.page-id-23507', event:'Form | Kontaktanfrage gesendet'},
        {
          selector:'.postid-24329',
          event:'Form | BrettBox Single Anfrage gesendet'
        },
        {
          selector:'.postid-24321',
          event:'Form | BrettBox Couple Anfrage gesendet'
        },
        {
          selector:'.postid-24314',
          event:'Form | BrettBox Family Anfrage gesendet'
        },
        {
          selector:'.postid-24299',
          event:'Form | BrettBox System Anfrage gesendet'
        },
      ];

      pages.forEach(page => {
        const pageElement=document.querySelector(page.selector);

        if(pageElement)
        {
          form.addEventListener('fluentform_submission_success', () => {
            // if(typeof plausible !== 'undefined')
            // {
            plausible(page.event);
            // }
          });
        }
      });
    }
  }
};

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init
};
