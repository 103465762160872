//------------------------------------------------------------------------------
// functions
//------------------------------------------------------------------------------
const load=() => {
  window.lazyLoadModules=window.lazyLoadModules || [];
  window.lazyLoadModules.forEach((item, index) => {

    let dependencies=item[0];
    let successCallback=item[1];
    let failureCallback=item[2];

    if(!dependencies)
    {
      return;
    }
    if(!Array.isArray(dependencies))
    {
      dependencies=[dependencies];
    }

    let promises=[];
    dependencies.filter((el) => {
      return !!el;
    }).forEach((module) => promises.push(import('./modules/'+module)));

    Promise.all(promises).then((values) => {
      if(typeof successCallback == 'function')
      {
        successCallback(...values);
      }
    }).catch((e) => {
      console.warn('Wasn\'t able to lazy load module due to following error: '+e.toString());
      if(typeof failureCallback == 'function')
      {
        failureCallback(e);
      }
    });
  })
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {load}
