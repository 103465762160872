import '../scss/bundle.scss';
import * as lazyModuleLoader from "./lazyModuleLoader";
import * as detectors from "./base/detectors";
import * as anchorLinks from "./base/anchorLinks";
import * as linkPrefetch from "./base/linkPrefetch";
import * as pagetracking from "./base/pagetracking";

//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------

// base
detectors.init();
anchorLinks.init();
linkPrefetch.init();
pagetracking.init();

// lazy module loader
lazyModuleLoader.load();
