import {getElementPageOffset} from "./util";

//------------------------------------------------------------------------------
// functions
//------------------------------------------------------------------------------
/**
 * @param {number} offset
 * @param {number} timeout
 * @param {ScrollBehavior} behavior
 */
const scrollTo=function(offset, timeout=0, behavior='smooth'){
  if(timeout>0)
  {
    setTimeout(() => {
      window.scrollTo({
        top:offset,
        behavior:behavior
      });
    }, timeout);
  }
  else
  {
    window.scrollTo({
      top:offset,
      behavior:behavior
    });
  }
}

/**
 * @param {Element} element
 * @param {number} timeout
 * @param {number} additionalOffset
 */
const scrollToElement=function(element, timeout=0, additionalOffset=0){
  const header=document.querySelector('#page-header:not(.sticky_auto_hide)');
  let offset=getElementPageOffset(element)-additionalOffset;
  if(!!header)
  {
    offset-=header.offsetHeight;
  }
  scrollTo(offset, timeout);
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  scrollTo,
  scrollToElement
}
